import style from "./detailedPriceList.module.css";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import "./quotationCreation.css";
import { Helmet } from "react-helmet";
import EditIcon from "@mui/icons-material/Edit";
import { ReactComponent as Filter } from "../icons/filter.svg";

import { ReactComponent as Search } from "../icons/search.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./searchPriceList.css";
import { UserContext } from "../App";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailedPriceList = () => {
  const { queryId } = useParams();
  // edit price list vars
  const [editDialog, setEditDialog] = useState(false);
  const handleEditDialog = () => {
    const current = editDialog;
    setEditDialog(!current);
  };

  const storedSearchResult = localStorage.getItem("cachedPLSearchResult");
  const initialSearchResult =
    storedSearchResult && storedSearchResult !== "undefined"
      ? JSON.parse(storedSearchResult)
      : [];
  const [searchResult, setSearchResult] = useState(initialSearchResult);
  const cachedPLLowestPriceSkuResult = localStorage.getItem(
    "cachedPLLowestPriceSkuResult"
  );
  const initialLowestPriceSkuResult =
    cachedPLLowestPriceSkuResult && cachedPLLowestPriceSkuResult !== "undefined"
      ? JSON.parse(cachedPLLowestPriceSkuResult)
      : [];
  const [lowestPriceSkuResult, setLowestPriceSkuResult] = useState(
    initialLowestPriceSkuResult
  );
  const storedSearchText = localStorage.getItem("searchPLText") || "";
  const [searchText, setSearchText] = useState(storedSearchText);
  const [loading, setIsloading] = useState(false);
  const navigate = useNavigate();

  const storedItemsString = localStorage.getItem("selectedItems");
  const storedItemsValue =
    storedItemsString && storedItemsString !== "undefined"
      ? JSON.parse(storedItemsString)
      : [];

  const { user } = useContext(UserContext);

  const [storedItemsChecklist, setStoredItemsChecklist] =
    useState(storedItemsValue);
  // const searchedItemsString = localStorage.getItem("cachedPLSearchedItems");
  // const seachedItems = searchedItemsString
  //   ? JSON.parse(searchedItemsString)
  //   : [];
  // const [cachedPLSearchList, setcachedPLSearchList] = useState(seachedItems);

  const storedSelectedOption = localStorage.getItem("cachedPLField");
  const initialSelectedOption =
    storedSelectedOption && storedSelectedOption !== "undefined"
      ? JSON.parse(storedSelectedOption)
      : null;
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  const storedSelectedOption2 = localStorage.getItem("cachedPLCategory");
  const initialSelectedOption2 =
    storedSelectedOption2 && storedSelectedOption2 !== "undefined"
      ? JSON.parse(storedSelectedOption2)
      : null;
  const [selectedOption2, setSelectedOption2] = useState(
    initialSelectedOption2
  );

  const storedSelectedOption3 = localStorage.getItem("cachedPLBrand");
  const initialSelectedOption3 =
    storedSelectedOption3 && storedSelectedOption3 !== "undefined"
      ? JSON.parse(storedSelectedOption3)
      : null;

  const [selectedOption3, setSelectedOption3] = useState(
    initialSelectedOption3
  );

  const storedSelectedOption4 = localStorage.getItem("cachedPLProduct");
  const initialSelectedOption4 =
    storedSelectedOption4 && storedSelectedOption4 !== "undefined"
      ? JSON.parse(storedSelectedOption4)
      : null;
  const [selectedOption4, setSelectedOption4] = useState(
    initialSelectedOption4
  );

  const storedBrandData = localStorage.getItem("brandsName");
  const storedBrandName =
    storedBrandData && storedBrandData !== "undefined"
      ? JSON.parse(storedBrandData)
      : [];
  const [brandData, setBrandData] = useState(storedBrandName);

  const storedProductData = localStorage.getItem("productsName");
  const storedProductName =
    storedProductData && storedProductData !== "undefined"
      ? JSON.parse(storedProductData)
      : [];
  const [productData, setProductData] = useState(storedProductName);
  const [page, setPage] = useState(1);
  const cachedPLQueryPerPage =
    localStorage.getItem("cachedPLQueryPerPage") || 1;
  const [queryPerPage, setQueryPerPage] = useState(
    parseInt(cachedPLQueryPerPage)
  );
  const cachedPLTotalQuery = localStorage.getItem("cachedPLTotalQuery") || 1;
  const [totalQuery, setTotalQuery] = useState(parseInt(cachedPLTotalQuery));
  const options2 = [
    { value: "Consumables (Low-value)", label: "Consumables (Low-value)" },
    { value: "Consumables (High-value)", label: "Consumables (High-value)" },
    { value: "Medical Devices", label: "Medical Devices" },
    {
      value: "Medical Devices (Pre-Owned)",
      label: "Medical Devices (Pre-Owned)",
    },
    { value: "Instruments", label: "Instruments" },
    { value: "Hospital Furniture", label: "Hospital Furniture" },
    { value: "OTC", label: "OTC" },
    { value: "Medical Gases", label: "Medical Gases" },
    { value: "Medicine", label: "Medicine" },
    { value: "Implant", label: "Implant" },
    {
      value: "Technical Devices (Non-Medical)",
      label: "Technical Devices (Non-Medical)",
    },
    { value: "Disposable", label: "Disposable" },
  ];
  const options = [
    { value: "Multi-use", label: "Multi-use" },
    { value: "General Surgery", label: "General Surgery" },
    { value: "Urology", label: "Urology" },
    { value: "Proctology", label: "Proctology" },
    { value: "Laparoscopy", label: "Laparoscopy" },
    { value: "Orthopaedics", label: "Orthopaedics" },
    { value: "Opthalmology", label: "Opthalmology" },
    { value: "Nephrology", label: "Nephrology" },
    { value: "Diagnostics", label: "Diagnostics" },
    { value: "Radiology", label: "Radiology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Vascular", label: "Vascular" },
    { value: "Gynaecology", label: "Gynaecology" },
    { value: "Bariatric", label: "Bariatric" },
    { value: "Gastroenterology", label: "Gastroenterology" },
    { value: "Cardiology", label: "Cardiology" },
    { value: "Anaesthesia", label: "Anaesthesia" },
    { value: "Dental", label: "Dental" },
    { value: "Infection Control", label: "Infection Control" },
    { value: "Neurosurgery", label: "Neurosurgery" },
    { value: "ENT", label: "ENT" },
    { value: "Physiotherapy", label: "Physiotherapy" },
    { value: "Oncology", label: "Oncology" },
  ];
  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("searchPLText", event.target.value);
  };

  const cachedPLSortState = localStorage.getItem("cachedPLSortState") || "";
  const cachedPLSortOrder = localStorage.getItem("cachedPLSortOrder") || "asc";
  const [sortState, setSortState] = useState(cachedPLSortState);
  const [sortOrder, setSortOrder] = useState(cachedPLSortOrder);
  const handleSearchButtonClick = async (e) => {
    if (
      !(searchText && searchText.length > 0) &&
      !selectedOption &&
      !selectedOption2 &&
      !selectedOption3 &&
      !selectedOption4
    ) {
      return;
    }
    setIsloading(true);

    const productCategoryValue = selectedOption2?.value || "";
    const medicalFieldValue = selectedOption?.value || "";
    const brandValue = selectedOption3?.value || "";
    const productValue = selectedOption4?.value || "";

    try {
      const response = await fetch(
        `/quotegen/priceList?searchText=${searchText}&productCategory=${productCategoryValue}&medicalFieldValue=${medicalFieldValue}&brandValue=${brandValue}&productValue=${productValue}&page=${page}&sortState=${sortState}&sortOrder=${sortOrder}&dPL=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { data, queryPerPage, totalDocuments } = await response.json();
      setQueryPerPage(queryPerPage);
      setTotalQuery(totalDocuments);
      setSearchResult(data);
      localStorage.setItem("cachedPLQueryPerPage", queryPerPage);
      localStorage.setItem("cachedPLTotalQuery", totalDocuments);
      localStorage.setItem("cachedPLSearchResult", JSON.stringify(data));
      const lowestPrices = {};

      data &&
        data.forEach((item) => {
          const { sku, cpegst } = item;
          if (
            !(sku in lowestPrices) ||
            Number(cpegst) < Number(lowestPrices[sku].cpegst)
          ) {
            lowestPrices[sku] = { ...item };
          }
        });

      setLowestPriceSkuResult(Object.values(lowestPrices));
      localStorage.setItem(
        "cachedPLLowestPriceSkuResult",
        JSON.stringify(Object.values(lowestPrices))
      );
      setIsloading(false);
    } catch (error) {
      toast.error(`Error Loading Product `, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsloading(false);
    }
  };

  const handleGetBrandsAndProducts = async (e) => {
    try {
      const response = await fetch(`/quotegen/brandsAndProducts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { brandData, productData } = await response.json();
      setBrandData(brandData);
      setProductData(productData);
      localStorage.setItem("brandsName", JSON.stringify(brandData));
      localStorage.setItem("productsName", JSON.stringify(productData));
    } catch (error) {
      toast.error(`Error Loading Products/Brands `, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (!storedProductData && !storedBrandData) {
      handleGetBrandsAndProducts();
    }
    getVendors();
    getWarranties();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    handleSearchButtonClick();
  }, [
    selectedOption,
    selectedOption2,
    selectedOption3,
    selectedOption4,
    page,
    sortState,
    sortOrder,
  ]);

  const handleCreateSku = () => {
    navigate("/adminsku");
  };

  const priceListHeader = [
    "Product Details",
    "Vendor",
    "Cost Per Unit(Excl GST)",
    "GST%",
    "Market Selling Price(Excl GST)",
    "Minimum Selling Price(Excl GST)",
    "MRP",
    "Margin",
    "Selling Price(Excl GST)",
    "Selling Price(Incl GST)",
    "Warranty",
    "Edit",
  ];

  const [paymentrows, setPaymentRows] = useState([
    {
      paymentTermstype: "Advance",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 100,
    },
    {
      paymentTermstype: "On Delivery",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "On Installation",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "Credit(In Days)",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "EMI(In Months)",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
  ]);

  const filteredHeaders = priceListHeader.filter((header) => {
    return user && user.role === "sales"
      ? header !== "Cost Per Unit(Excl GST)" && header !== "Margin"
      : header;
  });
  const [vendorsList, setVendorsList] = useState([]);
  const [warrantyList, setWarrantyList] = useState();
  const getVendors = async () => {
    const response = await fetch(`/quotegen/vendors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { success, data: vendorData } = await response.json();

    if (success) {
      const vendorNamesArray = vendorData.map((vendor) => ({
        value: vendor.name,
        label: vendor.name,
        prospect_id: vendor.prospect_id,
      }));
      setVendorsList(vendorNamesArray);
    }
  };
  const getWarranties = async () => {
    const response = await fetch("/quotegen/getWarranties");
    const data = await response.json();
    const warrantiesArrayMap = data.warranties.map((warranty) => ({
      value: warranty,
      label: warranty,
    }));
    setWarrantyList(warrantiesArrayMap);
  };

  const [editableData, setEditableData] = useState({});
  const [newAdminPanelVisibility, setNewAdminPanelVisibility] = useState();
  const [newVendor, setNewVendor] = useState();
  const [newPreferredVendor, setNewPreferredVendor] = useState(false);
  const [newWarranty, setNewWarranty] = useState();
  const [newCpuegst, setNewCpuegst] = useState();
  const [newMspegst, setNewMspegst] = useState();
  const [newMinspegst, setNewMinspegst] = useState();
  const [newMrp, setNewMrp] = useState();
  const [visibilityLoading, setVisibilityLoading] = useState();
  const [newSupplyCrackedSku, setNewSupplyCrackedSku] = useState();
  const [crackedLoading, setCrackedLoading] = useState();
  const handleEditRow = async (shortRow, row, otherRow) => {
    setEditableData(shortRow);
    setNewVendor({
      value: shortRow.partner_name,
      label: shortRow.partner_name,
    });
    setNewWarranty({
      value: shortRow.warranty_duration,
      label: shortRow.warranty_duration,
    });
    setNewCpuegst(shortRow.cpegst);
    setNewMspegst(shortRow.mspegst);
    setNewMinspegst();
    setNewMrp(shortRow.mrp);

    try {
      setVisibilityLoading(true);
      setCrackedLoading(true);
      const response = await fetch(
        `/quotegen/getProductAdminPanelVisibility?sku=${shortRow.sku}`
      );
      const data = await response.json();
      setNewAdminPanelVisibility(data.admin_panel_visibility);
      const response2 = await fetch(
        `/quotegen/getProductSupplyCrackedSKU?sku=${shortRow.sku}`
      );
      const data2 = await response2.json();
      setNewSupplyCrackedSku(data2.supplyCrackedSku);
    } catch (error) {
    } finally {
      setVisibilityLoading(false);
      setCrackedLoading(false);
    }
  };

  const handleSubmitEditRow = async () => {
    // console.log(editableData);
    let newData = editableData;
    newData = {
      ...newData,
      partner_name: newVendor.value,
      warranty_duration: newWarranty.value,
      cpegst: newCpuegst,
      mspegst: newMspegst,
      minspegst: newMinspegst,
      mrp: newMrp,
    };

    // console.log(newData);

    try {
      const response = await fetch("/quotegen/editPriceList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          editableData,
          newData,
          preferredVendor: newPreferredVendor,
          visibility: newAdminPanelVisibility,
          supplyCrackedSku: newSupplyCrackedSku,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update price list");
      }

      const result = await response.json();
      toast.success("Details will be updated soon", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error updating price list:", error);
      toast.error("Error updating price list", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isSelected ? "#003854" : "#003854",
      padding: "2px 5px",
      // width: "180px",
      textAlign: "left",
      borderRadius: "0px",
      boxShadow: "none",
      color: "black !important",
      backgroundColor: "#222831",
      width: "200px",
      fontWeight: 500,
      fontSize: "17px",
      alignContent: "initial",
      border: "1px solid rgb(203, 203, 203)",
      minHeight: "2.75rem",
      textTransform: "none",
      fontFamily: "Inter",
      cursor: "pointer",
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
      outline: "none",
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
      outline: "none",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      height: "fit-content",
      width: "fit-content",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...customStyles.control(provided, state),
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    }),
  };
  const customStyles3 = {
    control: (provided, state) => ({
      ...customStyles.control(provided, state),
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Filter
          style={{
            cursor: "pointer",
            padding: "0px",
            width: "20px",
            height: "20px",
          }}
        />
      </components.DropdownIndicator>
    );
  };

  // const ClearCategory = () => {
  //   return (
  //     <Cross
  //       style={{ cursor: "pointer" }}
  //       onClick={(event) => {
  //         event.stopPropagation();
  //         setSelectedOption2(null);
  //         setLowestPriceSkuResult([]);
  //       }}
  //     />
  //   );
  // };

  const handleSortBy = (event, priceL) => {
    localStorage.setItem("cachedPLSortState", priceL);
    setSortState(priceL);
    if (sortState !== "" && priceL === sortState) {
      setSortOrder(sortOrder === "asc" ? "dsc" : "asc");
      localStorage.setItem(
        "cachedPLSortOrder",
        sortOrder === "asc" ? "dsc" : "asc"
      );
    }
  };

  return (
    <div className={style.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Price List</title>
      </Helmet>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Box
            sx={{
              width: "100%",
              // maxWidth: "100%",
              display: "flex",
              alignItems: "center",
              // justifyContent:'space-evenly',
              gap: "10px",
              height: "fit-content !important",

              // justifyContent: "center",
              // "& > :first-of-type": {
              //   width: "50%",
              // },
              // "& > :last-child": {
              //   width: "15%", // Adjust this percentage as needed
              // },
              marginBottom: "3%",
            }}
          >
            <TextField
              fullWidth
              className="pricelist-textfield"
              // label="Search Products"
              id="fullWidth"
              placeholder="Search products..."
              value={searchText}
              onChange={handleSearchInputChange}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleSearchButtonClick() : null
              }
              sx={{
                flex: "1",
                padding: "0px !important",
                borderRadius: "5px",
                minWidth: "100px !important",
                height: "fit-content",
                maxWidth: "600px !important",
              }}
              InputProps={{
                endAdornment: (
                  // <InputAdornment
                  //   position="end"
                  //   sx={{ position: "absolute", right: "0px" , height:'45px !important' , padding:'0px !important' }}
                  // >
                  <Search
                    onClick={handleSearchButtonClick}
                    style={{
                      background: "#C9C9C9",
                      cursor: "pointer",
                      padding: "0px 10px",
                      borderBottomRightRadius: "3px",
                      borderTopRightRadius: "3px",
                      border: "none",
                      height: "44px",
                      position: "absolute",
                      right: "0px",
                    }}
                  />
                  // </InputAdornment>
                ),
              }}
            />

            <div style={{ display: "flex", gap: "px", zIndex: "10" }}>
              <Select
                value={selectedOption4}
                defaultValue={selectedOption4}
                placeholder="Product"
                isClearable={true}
                options={productData}
                styles={{ ...customStyles, ...customStyles2 }}
                components={{
                  DropdownIndicator,
                }}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setSelectedOption4(null);
                    setLowestPriceSkuResult([]);
                  } else {
                    setSelectedOption4(newValue);
                    localStorage.setItem(
                      "cachedPLProduct",
                      JSON.stringify(newValue)
                    );
                  }
                }}
              />

              <Select
                value={selectedOption3}
                defaultValue={selectedOption3}
                placeholder="Brand"
                isClearable={true}
                options={brandData}
                styles={customStyles}
                components={{
                  DropdownIndicator,
                }}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setSelectedOption3(null);
                    setLowestPriceSkuResult([]);
                  } else {
                    setSelectedOption3(newValue);
                    localStorage.setItem(
                      "cachedPLBrand",
                      JSON.stringify(newValue)
                    );
                  }
                }}
              />

              <Select
                value={selectedOption2}
                defaultValue={selectedOption2}
                placeholder="Category"
                isClearable={true}
                options={options2}
                styles={customStyles}
                components={{
                  DropdownIndicator,
                }}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setSelectedOption2(null);
                    setLowestPriceSkuResult([]);
                  } else {
                    setSelectedOption2(newValue);
                    localStorage.setItem(
                      "cachedPLCategory",
                      JSON.stringify(newValue)
                    );
                  }
                }}
              />
              <Select
                value={selectedOption}
                defaultValue={selectedOption}
                placeholder="Field"
                options={options}
                styles={{ ...customStyles, ...customStyles3 }}
                isClearable={true}
                components={{
                  DropdownIndicator,
                }}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setSelectedOption(null);
                    setLowestPriceSkuResult([]);
                  } else {
                    setSelectedOption(newValue);
                    console.log(newValue);
                    localStorage.setItem(
                      "cachedPLField",
                      JSON.stringify(newValue)
                    );
                  }
                }}
              />
            </div>

            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setSelectedOption(null);
                setSelectedOption2(null);
                setSelectedOption3(null);
                setSelectedOption4(null);
                setSearchText("");
                setSearchResult([]);
                setLowestPriceSkuResult([]);
                setSortState("");
                setSortOrder("asc");
                localStorage.removeItem("cachedPLField");
                localStorage.removeItem("cachedPLBrand");
                localStorage.removeItem("cachedPLCategory");
                localStorage.removeItem("cachedPLProduct");
                localStorage.removeItem("searchPLText");
                localStorage.removeItem("cachedPLLowestPriceSkuResult");
                localStorage.removeItem("cachedPLSearchResult");
                localStorage.removeItem("cachedPLTotalQuery");
                localStorage.removeItem("cachedPLQueryPerPage");
              }}
              sx={{ padding: "10.5px 0px" }}
            >
              &#10006;
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              // maxWidth: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "0%",
              gap: "10px",
              padding: "0px",
            }}
          ></Box>

          {lowestPriceSkuResult && lowestPriceSkuResult.length > 0 && (
            <>
              <TableContainer
                className="pricelist-table"
                sx={{ maxHeight: "150vh", marginTop: "0%" }}
                component={Paper}
              >
                <Table
                  sx={{ overflowY: "scroll" }}
                  aria-label="collapsible table"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#31363F",
                        position: "sticky",
                        top: "0px",
                        zIndex: "2",
                      }}
                    >
                      <TableCell />
                      {filteredHeaders.map((priceL) => {
                        return priceL === "Minimum Selling Price(Excl GST)" ||
                          priceL === "MRP" ? null : (
                          <TableCell
                            key={priceL}
                            align="center"
                            sx={{
                              color: "#ffffff",
                              fontFamily: "Inter-Bold, Helvetica",
                              fontSize: "15px",
                              fontWeight: "700",
                            }}
                          >
                            {priceL === "Market Selling Price(Excl GST)" ||
                            priceL === "Margin" ||
                            priceL === "Selling Price(Excl GST)" ||
                            priceL === "Selling Price(Incl GST)" ? (
                              <Button
                                // variant="outlined"
                                style={{
                                  padding: "0px",
                                  color: "#ffffff",
                                  fontFamily: "Inter-Bold, Helvetica",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  textTransform: "none",
                                  // backgroundColor:
                                  //   sortState === priceL ? "#3c78aa" : "#4286bd",
                                  height: "100%",
                                }}
                                onClick={(e) => {
                                  const toPass =
                                    priceL === "MSP, MP, MRP"
                                      ? "Market Selling Price(Excl GST)"
                                      : priceL;
                                  handleSortBy(e, priceL);
                                }}
                              >
                                {priceL === "Market Selling Price(Excl GST)" ? (
                                  <Tooltip title="Market Selling Price(Excl GST), Minimum Selling Price(Excl GST), MRP">
                                    MSP, MP, MRP
                                  </Tooltip>
                                ) : (
                                  priceL
                                )}{" "}
                                {sortState === priceL
                                  ? sortOrder === "asc"
                                    ? "↑"
                                    : "↓"
                                  : ""}
                              </Button>
                            ) : (
                              <TableCell
                                key={priceL}
                                align="center"
                                sx={{
                                  color: "#ffffff",
                                  fontFamily: "Inter-Bold, Helvetica",
                                  fontSize: "15px",
                                  fontWeight: "700",
                                  border: "none",
                                }}
                              >
                                {priceL}
                              </TableCell>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lowestPriceSkuResult &&
                      (user &&
                      user.role === "category" &&
                      window.location.pathname === "/manualQuotation"
                        ? lowestPriceSkuResult.filter(
                            (row) => row.cpegst !== ""
                          )
                        : lowestPriceSkuResult
                      ).map((row) => (
                        <RowPriceList
                          key={row._id}
                          row={row}
                          handleEditRow={handleEditRow}
                          isChecked={storedItemsChecklist.some(
                            (item) => item.sku === row.sku
                          )}
                          skuOtherPriceData={searchResult.filter(
                            (otherData) => otherData.sku === row.sku
                          )}
                          handleEditDialog={handleEditDialog}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "2px",
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "center",
                  // width:'100px',
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(totalQuery / queryPerPage)}
                    boundaryCount={1}
                    page={page}
                    siblingCount={2}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </>
          )}
          {editDialog && (
            <Dialog
              open={editDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleEditDialog}
              aria-describedby="alert-dialog-slide-description"
              maxWidth="sm"
              fullWidth="sm"
            >
              <DialogTitle>{`Editing - ${editableData.sku} - ${
                editableData.warranty_duration === "-"
                  ? "No warranty"
                  : editableData.warranty_duration
              }`}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <p>
                      {editableData.name} - {editableData.brand} -{" "}
                      {editableData.size}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        style={{ transform: "scale(1.5)", marginRight: "10px" }}
                        checked={newAdminPanelVisibility}
                        onChange={(e) =>
                          setNewAdminPanelVisibility(e.target.checked)
                        }
                        disabled={visibilityLoading}
                      />
                      Price List Visibility{" "}
                      {visibilityLoading ? "Loading..." : ""}
                    </label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        style={{ transform: "scale(1.5)", marginRight: "10px" }}
                        checked={newSupplyCrackedSku}
                        onChange={(e) =>
                          setNewSupplyCrackedSku(e.target.checked)
                        }
                        disabled={crackedLoading}
                      />
                      Supply Cracked SKU {crackedLoading ? "Loading..." : ""}
                    </label>
                  </div>
                  <div>
                    <p>
                      Current vendor*:{" "}
                      {editableData.partner_name === ""
                        ? "None"
                        : editableData.partner_name}{" "}
                    </p>
                    <Select
                      required
                      value={newVendor}
                      onChange={(newValue) => {
                        setNewVendor(newValue);
                      }}
                      options={vendorsList}
                    ></Select>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="checkbox"
                          style={{
                            transform: "scale(1.5)",
                            marginRight: "10px",
                          }}
                          checked={newPreferredVendor}
                          onChange={(e) =>
                            setNewPreferredVendor(e.target.checked)
                          }
                        />
                        Preferred vendor
                      </label>
                    </div>
                  </div>
                  <div>
                    <p>
                      Current warranty*:{" "}
                      {editableData.warranty_duration === "-"
                        ? "No warranty"
                        : editableData.warranty_duration}{" "}
                    </p>
                    <Select
                      required
                      value={newWarranty}
                      onChange={(newValue) => {
                        setNewWarranty(newValue);
                        setNewCpuegst("");
                      }}
                      options={warrantyList}
                    ></Select>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      required
                      margin="dense"
                      id="newCpuegst"
                      name="newCpuegst"
                      label="Cost Per Unit (Excl. GST)"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newCpuegst}
                      onChange={(e) => {
                        setNewCpuegst(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      margin="dense"
                      id="newMspegst"
                      name="newMspegst"
                      label="Market Selling Price (Excl. GST)"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newMspegst}
                      onChange={(e) => {
                        setNewMspegst(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      margin="dense"
                      id="newMinspegst"
                      name="newMinspegst"
                      label="Minimum Selling Price (Excl. GST)"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newMinspegst}
                      onChange={(e) => {
                        setNewMinspegst(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      margin="dense"
                      id="newMrp"
                      name="newMrp"
                      label="MRP"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newMrp}
                      onChange={(e) => {
                        setNewMrp(e.target.value);
                      }}
                    />
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditDialog}>Cancel</Button>
                <Button
                  onClick={() => {
                    if (newVendor.value === "" || newCpuegst === "") {
                      toast.error("Please fill all required inputs!");
                    } else {
                      handleSubmitEditRow();
                      handleEditDialog();
                    }
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <ToastContainer autoClose={1000} />
        </div>
      )}
    </div>
  );
};

const RowPriceList = ({
  row,
  handleEditRow,
  isChecked,
  skuOtherPriceData,
  handleEditDialog,
}) => {
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [selectedSkuPrice, setSelectedSkuPrice] = useState(row);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWarranty = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedWarranty, setSelectedWarranty] = useState(
    `${row.warranty_duration}`
  );

  const handleSelectWarranty = (warranty, item) => {
    setSelectedWarranty(warranty);
    setSelectedSkuPrice(item);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          border: "1px solid rgba(224, 224, 224, 1)",
          // background:'green'
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{
            fontWeight: 500,
            color: "#0671bf",
            maxWidth: 200,
            whiteSpace: "pre-wrap",
          }}
        >
          <div>{`${selectedSkuPrice.name}`}</div>
          <div>{`${selectedSkuPrice.brand}`}</div>
          <div>{`${selectedSkuPrice.size}`}</div>
          <div>{`${selectedSkuPrice.sku}`}</div>
        </TableCell>
        <TableCell>{selectedSkuPrice.partner_name}</TableCell>
        {user && user.role !== "sales" && (
          <TableCell
            align="left"
            style={{ fontWeight: 500 }}
            onClick={() => setOpen(!open)}
          >
            {selectedSkuPrice.cpegst}
          </TableCell>
        )}
        <TableCell
          align="left"
          style={{ fontWeight: 500 }}
          onClick={() => setOpen(!open)}
        >
          {selectedSkuPrice.gst}
        </TableCell>

        <TableCell
          align="left"
          style={{
            fontWeight: 500,
          }}
          onClick={() => setOpen(!open)}
        >
          {selectedSkuPrice.mspegst} <br /> {selectedSkuPrice.minspegst} <br />
          {selectedSkuPrice.mrp}
        </TableCell>
        {/* <TableCell align="left" onClick={() => setOpen(!open)}>
          {selectedSkuPrice.minspegst}
        </TableCell>
        <TableCell align="left" onClick={() => setOpen(!open)}>
          {selectedSkuPrice.mrp}
        </TableCell> */}
        {user && user.role !== "sales" && (
          <TableCell align="left" onClick={() => setOpen(!open)}>
            {selectedSkuPrice.margin}
          </TableCell>
        )}
        <TableCell align="left" onClick={() => setOpen(!open)}>
          {selectedSkuPrice.spegst
            ? parseFloat(selectedSkuPrice.spegst).toFixed(2)
            : ""}
        </TableCell>
        <TableCell align="left" onClick={() => setOpen(!open)}>
          {selectedSkuPrice.spigst
            ? parseFloat(selectedSkuPrice.spigst).toFixed(2)
            : ""}
        </TableCell>
        <TableCell align="center">
          <div>
            <Button
              id="fade-button"
              aria-controls={openWarranty ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openWarranty ? "true" : undefined}
              onClick={handleClick}
            >
              {selectedWarranty}
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openWarranty}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {skuOtherPriceData.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleSelectWarranty(item.warranty_duration, item);
                    handleClose();
                  }}
                >
                  {item.warranty_duration}{" "}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </TableCell>
        <TableCell align="left">
          {/* <Checkbox
            icon={<AddIcon />}
            checked={isChecked}
            onChange={() => handleEditRow(selectedSkuPrice)}
            disabled={isChecked}
          /> */}
          <Button
            onClick={() => {
              handleEditRow(selectedSkuPrice, row, skuOtherPriceData);
              handleEditDialog();
            }}
          >
            <EditIcon
              sx={{
                color: "#31363F",
              }}
            />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow style={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
        <TableCell
          colSpan={13}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            padding: 0,
            border: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 0,
                height: "fit-content",
                display: "flex",
                gap: "30px",
                width: "100vw",
                padding: "10px 0px 10px 25px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "flex-start",
                  "& > *:not(:last-child)": {
                    marginBottom: "15px",
                  },
                  minWidth: "240px",
                }}
              >
                {user && user.role === "category" && (
                  <Box>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#3279b3",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "16px",
                        fontWeight: 700,
                        minWidth: "240px",
                      }}
                    >
                      Partner Name
                    </Typography>

                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#333333",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {selectedSkuPrice.partner_name}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#3279b3",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "16px",
                      fontWeight: 700,
                      minWidth: "240px",
                    }}
                  >
                    Brand
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.brand}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#3279b3",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "16px",
                      fontWeight: 700,
                      minWidth: "240px",
                    }}
                  >
                    Size/Specification
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.size}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "flex-start",
                  "& > *:not(:last-child)": {
                    marginBottom: "15px",
                  },
                  minWidth: "240px",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#3279b3",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Product Categories
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.prodcat}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#3279b3",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Medical Fields
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.category}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.Medical_Field_2}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.Medical_Field_3}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#3279b3",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    HSN Code
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#333333",
                      fontFamily: "Inter-Bold, Helvetica",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.HSN}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "220px",
                  margin: "0",
                }}
              >
                <Slider {...settings}>
                  {row.image &&
                    row.image.map((url, index) => (
                      <div key={index}>
                        <img
                          src={url}
                          alt={`Slide ${index + 1}`}
                          style={{ width: "100%", height: "200px" }}
                        />
                      </div>
                    ))}
                </Slider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflowY: "hidden",
                  // paddingBottom
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#3279b3",
                    fontFamily: "Inter-Bold, Helvetica",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                  component="div"
                >
                  Description
                </Typography>
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  value={row.description}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    height: "fit-content",
                    // overflowY: "scroll",
                    border: "1px solid #ccc",
                    padding: "8px",
                    width: "450px",
                    backgroundColor: "#f6f6f6",
                    borderRadius: "3px",
                  }}
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default DetailedPriceList;
